import React from "react";
import BlueBoxContainer from "./BlueBoxContainer";
import GreenBoxContainer from "./GreenBoxContainer";
import YellowBoxContainer from "./YellowBoxContainer";
import WhiteBoxContainer from "./WhiteBoxContainer";

const SectionContent = ({
    sectionEstimates,
    sectionContent,
    comingFrom,
    blue_box_title,
    blue_box_description,
    green_box_title,
    green_box_description,
    second_blue_box_title,
    second_blue_box_description,
    second_green_box_title,
    second_green_box_description,
    analysisSectionTextDescription,
    first_white_box_title,
    first_white_box_description,
    second_white_box_title,
    second_white_box_description,
    third_white_box_title,
    third_white_box_description,
    yellow_box_title,
    yellow_box_description,
    second_yellow_box_title,
    second_yellow_box_description
}) => {
    return (
        <div className="section-content">
            {comingFrom == "Market Opportunity" && (
                <div className="section-estimates" style={{ marginTop: "20px" }}>
                    {(blue_box_title) && (
                        <BlueBoxContainer
                            blue_box_title={blue_box_title}
                            blue_box_description={blue_box_description}
                        />
                    )}

                    {(green_box_title) && (
                        <GreenBoxContainer
                            green_box_title={green_box_title}
                            green_box_description={green_box_description}
                            comingFrom={comingFrom}
                        />
                    )}

                    {(yellow_box_title) && (
                        <YellowBoxContainer
                            yellow_box_title={yellow_box_title}
                            yellow_box_description={yellow_box_description}
                            comingFrom={comingFrom}
                        />
                    )}

                </div>
            )}

            {comingFrom == "Competitive Landscape" && (
                <div className="section-estimates" style={{ marginTop: "20px" }}>
                    {(blue_box_title) && (
                        <BlueBoxContainer
                            blue_box_title={blue_box_title}
                            blue_box_description={blue_box_description}
                        />
                    )}

                    {(green_box_title) && (
                        <GreenBoxContainer
                            green_box_title={green_box_title}
                            green_box_description={green_box_description}
                            comingFrom={comingFrom}
                        />
                    )}

                    {(yellow_box_title) && (
                        <YellowBoxContainer
                            yellow_box_title={yellow_box_title}
                            yellow_box_description={yellow_box_description}
                            comingFrom={comingFrom}
                        />
                    )}
                </div>
            )}

            {comingFrom == "Product and Development Stage" && (
                <div className="section-estimates" style={{ marginTop: "20px" }}>
                    {(blue_box_title) && (
                        <BlueBoxContainer
                            blue_box_title={blue_box_title}
                            blue_box_description={blue_box_description}
                        />
                    )}

                    {(green_box_title) && (
                        <GreenBoxContainer
                            green_box_title={green_box_title}
                            green_box_description={green_box_description}
                            comingFrom={comingFrom}
                        />
                    )}


                    {(yellow_box_title) && (
                        <YellowBoxContainer
                            yellow_box_title={yellow_box_title}
                            yellow_box_description={yellow_box_description}
                            comingFrom={comingFrom}
                        />
                    )}
                </div>
            )}

            {comingFrom == "Financial Metrics" && (
                <>
                    <div className="section-estimates" style={{ marginTop: "20px" }}>
                        {(blue_box_title) && (
                            <BlueBoxContainer
                                blue_box_title={blue_box_title}
                                blue_box_description={blue_box_description}
                            />
                        )}

                        {(green_box_title) && (
                            <GreenBoxContainer
                                green_box_title={green_box_title}
                                green_box_description={green_box_description}
                                comingFrom={comingFrom}
                            />
                        )}
                        {(yellow_box_title) && (
                            <YellowBoxContainer
                                yellow_box_title={yellow_box_title}
                                yellow_box_description={yellow_box_description}
                                comingFrom={comingFrom}
                            />
                        )}
                    </div>

                    <div className="section-estimates" style={{ marginTop: "20px" }}>
                        {(second_blue_box_description) && (
                            <BlueBoxContainer
                                blue_box_title={second_blue_box_title}
                                blue_box_description={second_blue_box_description}
                            />
                        )}

                        {(second_green_box_description) && (
                            <GreenBoxContainer
                                green_box_title={second_green_box_title}
                                green_box_description={second_green_box_description}
                                comingFrom={comingFrom}
                            />
                        )}

                        {(second_yellow_box_title) && (
                            <YellowBoxContainer
                                yellow_box_title={second_yellow_box_title}
                                yellow_box_description={second_yellow_box_description}
                                comingFrom={comingFrom}
                            />
                        )}
                    </div>
                </>
            )}

            {comingFrom == "Traction And Market Penetration" && (
                <div className="section-estimates" style={{ marginTop: "20px" }}>
                    {(blue_box_title) && (
                        <BlueBoxContainer
                            blue_box_title={blue_box_title}
                            blue_box_description={blue_box_description}
                        />
                    )}

                    {(green_box_title) && (
                        <GreenBoxContainer
                            green_box_title={green_box_title}
                            green_box_description={green_box_description}
                            comingFrom={comingFrom}
                        />
                    )}

                    {(yellow_box_title) && (
                        <YellowBoxContainer
                            yellow_box_title={yellow_box_title}
                            yellow_box_description={yellow_box_description}
                            comingFrom={comingFrom}
                        />
                    )}
                </div>
            )}

            {comingFrom == "Risk Analysis" && (
                // <>
                //     <div className="section-estimates" style={{ marginTop: "20px" }}>
                //         <WhiteBoxContainer white_box_title={first_white_box_title} white_box_description={first_white_box_description} />
                //         <WhiteBoxContainer white_box_title={second_white_box_title} white_box_description={second_white_box_description} />
                //         <WhiteBoxContainer white_box_title={third_white_box_title} white_box_description={third_white_box_description} />
                //     </div>

                //     {/* <div className="section-estimates" style={{ marginTop: "20px" }}>
                //         <WhiteBoxContainer />
                //         <WhiteBoxContainer />
                //         <WhiteBoxContainer />
                //     </div> */}
                // </>
                <div className="section-estimates" style={{ marginTop: "20px" }}>
                    {(blue_box_title) && (
                        <BlueBoxContainer
                            blue_box_title={blue_box_title}
                            blue_box_description={blue_box_description}
                        />
                    )}

                    {(green_box_title) && (
                        <GreenBoxContainer
                            green_box_title={green_box_title}
                            green_box_description={green_box_description}
                            comingFrom={comingFrom}
                        />
                    )}

                    {(yellow_box_title) && (
                        <YellowBoxContainer
                            yellow_box_title={yellow_box_title}
                            yellow_box_description={yellow_box_description}
                            comingFrom={comingFrom}
                        />
                    )}
                </div>
            )}


            {comingFrom == "Team And Experience" && (
                <div className="section-estimates" style={{ marginTop: "20px" }}>
                    {(blue_box_title) && (
                        <BlueBoxContainer
                            blue_box_title={blue_box_title}
                            blue_box_description={blue_box_description}
                        />
                    )}

                    {(green_box_title) && (
                        <GreenBoxContainer
                            green_box_title={green_box_title}
                            green_box_description={green_box_description}
                            comingFrom={comingFrom}
                        />
                    )}

                    {(yellow_box_title) && (
                        <YellowBoxContainer
                            yellow_box_title={yellow_box_title}
                            yellow_box_description={yellow_box_description}
                            comingFrom={comingFrom}
                        />
                    )}
                </div>
            )}


            {comingFrom == "Governance And Support" && (
                <div className="section-estimates" style={{ marginTop: "20px" }}>
                    {(blue_box_title) && (
                        <BlueBoxContainer
                            blue_box_title={blue_box_title}
                            blue_box_description={blue_box_description}
                        />
                    )}

                    {(green_box_title) && (
                        <GreenBoxContainer
                            green_box_title={green_box_title}
                            green_box_description={green_box_description}
                            comingFrom={comingFrom}
                        />
                    )}
                </div>
            )}

            {comingFrom == "Revenue Model And Growth" && (
                <div className="section-estimates" style={{ marginTop: "20px" }}>
                    {(blue_box_title) && (
                        <BlueBoxContainer
                            blue_box_title={blue_box_title}
                            blue_box_description={blue_box_description}
                        />
                    )}

                    {(green_box_title) && (
                        <GreenBoxContainer
                            green_box_title={green_box_title}
                            green_box_description={green_box_description}
                            comingFrom={comingFrom}
                        />
                    )}
                    {(yellow_box_title) && (
                        <YellowBoxContainer
                            yellow_box_title={yellow_box_title}
                            yellow_box_description={yellow_box_description}
                            comingFrom={comingFrom}
                        />
                    )}
                </div>
            )}

            {comingFrom == "Exit Stratergy" && (
                <div className="section-estimates" style={{ marginTop: "20px" }}>
                    {(blue_box_title) && (
                        <BlueBoxContainer
                            blue_box_title={blue_box_title}
                            blue_box_description={blue_box_description}
                        />
                    )}

                    {(green_box_title) && (
                        <GreenBoxContainer
                            green_box_title={green_box_title}
                            green_box_description={green_box_description}
                            comingFrom={comingFrom}
                        />
                    )}
                </div>
            )}

            {comingFrom == "Funding and Capital Requirements" && (
                <div className="section-estimates" style={{ marginTop: "20px" }}>
                    {(blue_box_title) && (
                        <BlueBoxContainer
                            blue_box_title={blue_box_title}
                            blue_box_description={blue_box_description}
                        />
                    )}

                    {(green_box_title) && (
                        <GreenBoxContainer
                            green_box_title={green_box_title}
                            green_box_description={green_box_description}
                            comingFrom={comingFrom}
                        />
                    )}

                    {(yellow_box_title) && (
                        <YellowBoxContainer
                            yellow_box_title={yellow_box_title}
                            yellow_box_description={yellow_box_description}
                            comingFrom={comingFrom}
                        />
                    )}
                </div>
            )}


            {comingFrom === "StrengthAndWeakness" && (
                <div className="strengthAndWeaknessTable">
                    <div className="strengthTableColumn">
                        <div className="strengthTableColumnTitle">Strengths</div>
                        <div className="strengthBorderLine"></div>
                        <div className="strengths">
                            <div>1. Large market opportunity (TAM and SAM).</div>
                            <div>
                                2. Strong differentiation through customer service and brand
                                reputation.
                            </div>
                            <div>3. Experienced team with strong technical skills.</div>
                            <div>4. Initial traction with sales and partnerships.</div>
                            <div>5. Structured governance with a board of directors.</div>
                        </div>
                    </div>
                    <div className="weaknessTableColumnm">
                        <div className="weaknessTableColumnmTitle">Weakness</div>
                        <div className="weaknessBorderLine"></div>
                        <div className="weakness">
                            <div>
                                1. Product still in beta stage and no unique innovations.
                            </div>
                            <div>2. Small user base and low current revenue.</div>
                            <div>3. Long break-even timeline and sales cycle.</div>
                            <div>4. Political and economic risks in South Africa.</div>
                            <div>5. Lack of patents and single revenue stream.</div>
                        </div>
                    </div>
                </div>
            )}

            {comingFrom !== "StrengthAndWeakness" &&
                comingFrom !== "Recommendations" && (
                    <div className="section-analysis">
                        <p className="analysisTitle">Analysis</p>
                        <div className="analysis-text">
                            {analysisSectionTextDescription}
                        </div>
                    </div>
                )}

            {comingFrom === "Recommendations" && (
                <div className="listOfRecommendations">
                    <div className="recommendationItem">
                        <div className="recommendationItemTitle">
                            1. Accelerate User Growth:
                        </div>
                        <div className="recommendationItemDescription">
                            Focus on scaling the user base rapidly to demonstrate market
                            demand and increase revenue.
                        </div>
                    </div>
                    <div className="recommendationItem">
                        <div className="recommendationItemTitle">
                            2. Product Development
                        </div>
                        <div className="recommendationItemDescription">
                            Continue refining the product and introduce unique innovations to
                            strengthen competitive advantage.
                        </div>
                    </div>
                    <div className="recommendationItem">
                        <div className="recommendationItemTitle">3. Risk Mitigation</div>
                        <div className="recommendationItemDescription">
                            Develop strategies to mitigate political and economic risks,
                            including contingency planning.
                        </div>
                    </div>
                    <div className="recommendationItem">
                        <div className="recommendationItemTitle">
                            4. Financial Management
                        </div>
                        <div className="recommendationItemDescription">
                            Ensure careful financial management to reach break-even within the
                            targeted timeline.
                        </div>
                    </div>
                    <div className="recommendationItem">
                        <div className="recommendationItemTitle">
                            5. Investor Communication
                        </div>
                        <div className="recommendationItemDescription">
                            Clearly communicate the potential for growth and the plan to
                            manage risks to attract the $100,000 investment.
                        </div>
                    </div>

                    <div className="recommendationConclusion">
                        Overall, the company shows promise but needs to address certain
                        risks and scale its operations to become more attractive to
                        investors.
                    </div>
                </div>
            )}
        </div>
    );
};

export default SectionContent;
