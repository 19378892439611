import React from "react";
import SectionTitle from "../sectionComponents/SectionTitle";
import SectionContent from "../sectionComponents/SectionContent";

const FundingandCapitalRequirements = ({ fundingandCapitalRequirementsSectionData, assessment }) => {
    const sample_section_estimates = "The World Agricultural Supply and Demand Estimates (WASDE) is prepared and released by the World Agricultural Outlook Board (WAOB). The report is released monthly, and provides annual forecasts for supply and use of U.S. and world wheat, rice, coarse grains, oilseeds, and cotton. ";
    const section_sample_content = "The market opportunity is substantial, indicating significant potential for growth. A large TAM and SAM suggest that there is a sizable market for the product, which is attractive to investors.";

    let blue_box_data;
    let blue_box_data_list;
    let blue_box_title;
    let blue_box_description;

    let green_box_data;
    let green_box_data_list;
    let green_box_title;
    let green_box_description;

    let yellow_box_data;
    let yellow_box_data_list;
    let yellow_box_title;
    let yellow_box_description;

    let analysisSectionTextDescription;

    if (Array.isArray(fundingandCapitalRequirementsSectionData)) {
        blue_box_data = fundingandCapitalRequirementsSectionData[0] ? fundingandCapitalRequirementsSectionData[0] : "Metric not Available";
        blue_box_data_list = blue_box_data !== "" ? blue_box_data.split(":") : null;
        blue_box_title = blue_box_data_list !== null ? blue_box_data_list[0] : "Current Funding Requirement";
        blue_box_description = blue_box_data_list !== null ? blue_box_data_list[1] : "Metric Value not Available";

        green_box_data = fundingandCapitalRequirementsSectionData[1] ? fundingandCapitalRequirementsSectionData[1] : "Metric not Available";
        green_box_data_list = green_box_data !== "" ? green_box_data.split(":") : null;
        green_box_title = green_box_data_list !== null ? green_box_data_list[0] : "Previous Funding";
        green_box_description = green_box_data_list !== null ? green_box_data_list[1] : "Metric Value not Available";

        analysisSectionTextDescription = fundingandCapitalRequirementsSectionData[2] ? fundingandCapitalRequirementsSectionData[2] : "Analysis is not available at this moment for this metric.";
    }
    else if (typeof fundingandCapitalRequirementsSectionData === "object" && fundingandCapitalRequirementsSectionData !== null && !Array.isArray(fundingandCapitalRequirementsSectionData)) {
        blue_box_title = "Current Funding Requirement";
        // blue_box_description = fundingandCapitalRequirementsSectionData["Current Funding Requirement"] || fundingandCapitalRequirementsSectionData["Current Request"];
        blue_box_description = assessment?.how_much_usd_looking_to_raise || "";
        
        green_box_title = "Previous Funding Type";
        // green_box_description = fundingandCapitalRequirementsSectionData["Previous Funding"];
        green_box_description = assessment?.prev_received_financing || ""; 
        
        yellow_box_title = "Current funding type";
        // yellow_box_description = fundingandCapitalRequirementsSectionData["Overall Investment Readiness Assessment"];
        yellow_box_description = assessment?.financing_type_looking_for || "";
        
        analysisSectionTextDescription = fundingandCapitalRequirementsSectionData["Analysis"];
    } else {
        blue_box_title = "Current Funding Requirement";
        blue_box_description = "Metric Value not Available";
        green_box_title = "Previous Funding";
        green_box_description = "Metric Value not Available";
        analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    }

    return (
        <div className="sectionContainer">
            <SectionTitle sectionTitle="Funding and Capital Requirements" />
            <SectionContent
                sectionEstimates={sample_section_estimates}
                sectionContent={section_sample_content}
                blue_box_title={blue_box_title}
                blue_box_description={blue_box_description}
                green_box_title={green_box_title}
                green_box_description={green_box_description}
                yellow_box_title={yellow_box_title}
                yellow_box_description={yellow_box_description}
                analysisSectionTextDescription={analysisSectionTextDescription}
                comingFrom="Funding and Capital Requirements"
            />
        </div>
    );
};

export default FundingandCapitalRequirements;