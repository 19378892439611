import React from "react";
import SectionContent from "../sectionComponents/SectionContent";
import SectionTitle from "../sectionComponents/SectionTitle";

const RiskAnalysis = ({ riskAnalysisSectionData, assessment }) => {
    const sample_section_estimates = "The World Agricultural Supply and Demand Estimates (WASDE) is prepared and released by the World Agricultural Outlook Board (WAOB). The report is released monthly, and provides annual forecasts for supply and use of U.S. and world wheat, rice, coarse grains, oilseeds, and cotton. ";
    const section_sample_content = "Lebanon’s health ministry says 492 killed, including 35 children and 58 women. More than 1,600 wounded. Israel says 1,300 Hezbollah assets struck. The report also covers U.S. supply and use of sugar, meat, poultry eggs and milk, as well as Mexico’s supply and use of sugar. The WAOB chairs the Interagency Commodity Estimates Committees (ICECs), which include analysts from key USDA agencies who compile and interpret information from USDA and other domestic and foreign sources to produce the report. For more information about the WASDE process and data, visit the WASDE FAQs page.";

    let first_white_box_data;
    let first_white_box_data_list;
    let first_white_box_title;
    let first_white_box_description;

    let second_white_box_data;
    let second_white_box_data_list;
    let second_white_box_title;
    let second_white_box_description;

    let third_white_box_data;
    let third_white_box_data_list;
    let third_white_box_title;
    let third_white_box_description;

    let blue_box_data;
    let blue_box_data_list;
    let blue_box_title;
    let blue_box_description;

    let green_box_data;
    let green_box_data_list;
    let green_box_title;
    let green_box_description;

    let yellow_box_data;
    let yellow_box_data_list;
    let yellow_box_title;
    let yellow_box_description;

    let analysisSectionTextDescription;

    const combineWithAnd = (array) => {
        if (array.length === 0) return "";
        if (array.length === 1) return array[0];
        if (array.length === 2) return array.join(" and ");
        return array.slice(0, -1).join(", ") + ", and " + array[array.length - 1];
    };

    if (Array.isArray(riskAnalysisSectionData)) {
        first_white_box_data = riskAnalysisSectionData[0] ? riskAnalysisSectionData[0] : "Metric not Available";
        first_white_box_data_list = first_white_box_data !== "" ? first_white_box_data.split(":") : null;
        first_white_box_title = first_white_box_data_list !== null ? first_white_box_data_list[0] : "Economic and Technological Risks";
        first_white_box_description = first_white_box_data_list !== null ? first_white_box_data_list[1] : "Metric Value not Available";

        second_white_box_data = riskAnalysisSectionData[1] ? riskAnalysisSectionData[1] : "Metric not Available";
        second_white_box_data_list = second_white_box_data !== "" ? second_white_box_data.split(":") : null;
        second_white_box_title = second_white_box_data_list !== null ? second_white_box_data_list[0] : "Compliance";
        second_white_box_description = second_white_box_data_list !== null ? second_white_box_data_list[1] : "Metric Value not Available";

        third_white_box_data = riskAnalysisSectionData[2] ? riskAnalysisSectionData[2] : "Metric not Available";
        third_white_box_data_list = third_white_box_data !== "" ? third_white_box_data.split(":") : null;
        third_white_box_title = third_white_box_data_list !== null ? third_white_box_data_list[0] : "Patents";
        third_white_box_description = third_white_box_data_list !== null ? third_white_box_data_list[1] : "Metric Value not Available";

        analysisSectionTextDescription = riskAnalysisSectionData[3] ? riskAnalysisSectionData[3] : "Analysis is not available at this moment for this metric.";
    }
    else if (typeof riskAnalysisSectionData === "object" && riskAnalysisSectionData !== null && !Array.isArray(riskAnalysisSectionData)) {
        // first_white_box_title = "Pestel";
        // // first_white_box_description = riskAnalysisSectionData["Challenges"] || riskAnalysisSectionData["Identified Risks"];
        // first_white_box_description = combineWithAnd(assessment?.challenges_business_face);
        
        // second_white_box_title = "Compliance measures";
        // // second_white_box_description = riskAnalysisSectionData["Compliance"];
        // second_white_box_description = assessment?.compliance_industry_regulation || "";
        
        // third_white_box_title = "IP Protection";
        // // third_white_box_description = riskAnalysisSectionData["IP Protection"];
        // third_white_box_description = assessment?.measures_to_protect_ip || "";

        blue_box_title = "Pestel";
        // blue_box_description = revenueModelAndGrowthSectionData["Revenue Streams"];
        blue_box_description = combineWithAnd(assessment?.challenges_business_face);
        
        green_box_title = "Compliance measures";
        // green_box_description = revenueModelAndGrowthSectionData["Product Type"] || revenueModelAndGrowthSectionData["Business Model"];
        green_box_description = combineWithAnd(assessment?.compliance_industry_regulation);

        yellow_box_title = "IP Protection";
        // yellow_box_description = revenueModelAndGrowthSectionData["Projected Annual Growth Rate"];
        yellow_box_description = assessment?.measures_to_protect_ip || "";
        
        analysisSectionTextDescription = riskAnalysisSectionData["Analysis"];
    } else {
        first_white_box_title = "Economic and Technological Risks";
        first_white_box_description = "Metric Value not Available";
        second_white_box_title = "Compliance";
        second_white_box_description = "Metric Value not Available";
        third_white_box_title = "Patents";
        third_white_box_description = "Metric Value not Available";
        analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    }

    return (
        <div className="sectionContainer">
            <SectionTitle sectionTitle="Risk Analysis" />
            <SectionContent
                sectionEstimates={sample_section_estimates}
                sectionContent={section_sample_content}
                blue_box_title={blue_box_title}
                blue_box_description={blue_box_description}
                green_box_title={green_box_title}
                green_box_description={green_box_description}
                yellow_box_title={yellow_box_title}
                yellow_box_description={yellow_box_description}
                analysisSectionTextDescription={analysisSectionTextDescription}
                comingFrom="Risk Analysis"
            />
        </div>
    );
};

export default RiskAnalysis;