import React from "react";
import SectionTitle from "../sectionComponents/SectionTitle";
import SectionContent from "../sectionComponents/SectionContent";

const MarketOpportunity = ({ marketOpportunitySectionData, assessment }) => {
    const sample_section_estimates = "The World Agricultural Supply and Demand Estimates (WASDE) is prepared and released by the World Agricultural Outlook Board (WAOB). The report is released monthly, and provides annual forecasts for supply and use of U.S. and world wheat, rice, coarse grains, oilseeds, and cotton. ";
    const section_sample_content = "The market opportunity is substantial, indicating significant potential for growth. A large TAM and SAM suggest that there is a sizable market for the product, which is attractive to investors.";

    let blue_box_data;
    let blue_box_data_list;
    let blue_box_title;
    let blue_box_description;

    let green_box_data;
    let green_box_data_list;
    let green_box_title;
    let green_box_description;

    let yellow_box_data;
    let yellow_box_data_list;
    let yellow_box_title;
    let yellow_box_description;

    let analysisSectionTextDescription;

    console.log(marketOpportunitySectionData);

    if (Array.isArray(marketOpportunitySectionData)) {
        blue_box_data = marketOpportunitySectionData[0] ? marketOpportunitySectionData[0] : "Metric not Available";
        blue_box_data_list = blue_box_data !== "" ? blue_box_data.split(":") : null;
        blue_box_title = blue_box_data_list !== null ? blue_box_data_list[0] : "TAM (Total Addressable Market)";
        blue_box_description = blue_box_data_list !== null ? blue_box_data_list[1] : "Metric Value not Available";

        green_box_data = marketOpportunitySectionData[1] ? marketOpportunitySectionData[1] : "Metric not Available";
        green_box_data_list = green_box_data !== "" ? green_box_data.split(":") : null;
        green_box_title = green_box_data_list !== null ? green_box_data_list[0] : "SAM (Serviceable Addressable Market)";
        green_box_description = green_box_data_list !== null ? green_box_data_list[1] : "Metric Value not Available";

        analysisSectionTextDescription = marketOpportunitySectionData[2] ? marketOpportunitySectionData[2] : "Analysis is not available at this moment for this metric.";
    }
    else if (typeof marketOpportunitySectionData === "object" && marketOpportunitySectionData !== null && !Array.isArray(marketOpportunitySectionData)) {
        blue_box_title = "TAM (Total Addressable Market)";
        // blue_box_description = marketOpportunitySectionData["Total Addressable Market (TAM)"] || marketOpportunitySectionData["Total Addressable Market"];
        blue_box_description = assessment?.market_segment_target || "";

        green_box_title = "SAM (Serviceable Addressable Market)";
        // green_box_description = marketOpportunitySectionData["Serviceable Addressable Market (SAM)"] || marketOpportunitySectionData["Serviceable Addressable Market"];
        green_box_description = (assessment?.total_market_demand_for_product || "");
        if (green_box_description !== "") {
            green_box_description = green_box_description + " of TAM";
        }

        yellow_box_title = "Identified Market Segments";
        // yellow_box_description = marketOpportunitySectionData["Identified Market Segments"];
        yellow_box_description = "" + (assessment?.company_industry || "") + " Industry in " + (assessment?.company_country || "");

        analysisSectionTextDescription = marketOpportunitySectionData["Analysis"];
    } else {
        blue_box_title = "TAM (Total Addressable Market)";
        blue_box_description = "Metric Value not Available";
        green_box_title = "SAM (Serviceable Addressable Market)";
        green_box_description = "Metric Value not Available";
        analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    }

    return (
        <div className="sectionContainer">
            <SectionTitle sectionTitle="Market Opportunity" />
            <SectionContent
                sectionEstimates={sample_section_estimates}
                sectionContent={section_sample_content}
                blue_box_title={blue_box_title}
                blue_box_description={blue_box_description}
                green_box_title={green_box_title}
                green_box_description={green_box_description}
                yellow_box_title={yellow_box_title}
                yellow_box_description={yellow_box_description}
                analysisSectionTextDescription={analysisSectionTextDescription}
                comingFrom="Market Opportunity"
            />
        </div>
    );
};

export default MarketOpportunity;