import React from "react";
import CompetitiveLandscape from "./CompetitiveLandscape";
import ExitStratergy from "./ExitStratergy";
import FinancialMetrics from "./FinancialMetrics";
import GovernanceAndSupport from "./GovernanceAndSupport";
import MarketOpportunity from "./MarketOpportunity";
import ProductAndDevelopmentStage from "./ProductAndDevelopmentStage";
import Recommendations from "./Recommendations";
import RevenueModelAndGrowth from "./RevenueModelAndGrowth";
import RiskAnalysis from "./RiskAnalysis";
import StrengthAndWeakness from "./StrengthAndWeakness";
import TeamAndExperience from "./TeamAndExperience";
import TractionAndMarketPenetration from "./TractionAndMarketPenetration";
import FundingandCapitalRequirements from "./FundingandCapitalRequirements";


const AssessmentAnalysisContent = ({ parentStateForActiveTab, analysisTabRefs, analysisTabData, completeReportData = {} }) => {

    const assessment_data_submitted_by_the_business = completeReportData?.assessment || {};

    return (
        <div className="analysisContent">

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Market Opportunity"]) && (
                <div ref={analysisTabRefs.marketOpportunity}>
                    <MarketOpportunity marketOpportunitySectionData={analysisTabData.report.investment_readiness_analysis["Market Opportunity"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Competitive Landscape"]) && (
                <div ref={analysisTabRefs.competitiveLandscape}>
                    <CompetitiveLandscape competitiveLandscapeSectionData={analysisTabData.report.investment_readiness_analysis["Competitive Landscape"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Product and Development"]) && (
                <div ref={analysisTabRefs.productAndDevelopmentStage}>
                    <ProductAndDevelopmentStage productAndDevelopmentStageSectionData={analysisTabData.report.investment_readiness_analysis["Product and Development"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Financial Metrics"]) && (
                <div ref={analysisTabRefs.financialMetrics}>
                    <FinancialMetrics financialMetricsSectionData={analysisTabData.report.investment_readiness_analysis["Financial Metrics"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Traction and Market Penetration"]) && (
                <div ref={analysisTabRefs.tractionAndMarketPenetration}>
                    <TractionAndMarketPenetration tractionAndMarketPenetrationSectionData={analysisTabData.report.investment_readiness_analysis["Traction and Market Penetration"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Risk Analysis"]) && (
                <div ref={analysisTabRefs.riskAnalysis}>
                    <RiskAnalysis riskAnalysisSectionData={analysisTabData.report.investment_readiness_analysis["Risk Analysis"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Team and Experience"]) && (
                <div ref={analysisTabRefs.teamAndExperience}>
                    <TeamAndExperience teamAndExperienceSectionData={analysisTabData.report.investment_readiness_analysis["Team and Experience"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Governance and Support"]) && (
                <div ref={analysisTabRefs.governanceAndSupport}>
                    <GovernanceAndSupport governanceAndSupportSectionData={analysisTabData.report.investment_readiness_analysis["Governance and Support"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Revenue Model and Growth"]) && (
                <div ref={analysisTabRefs.revenueModelAndGrowth}>
                    <RevenueModelAndGrowth revenueModelAndGrowthSectionData={analysisTabData.report.investment_readiness_analysis["Revenue Model and Growth"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Exit Strategy"]) && (
                <div ref={analysisTabRefs.exitStratergy}>
                    <ExitStratergy exitStratergySectionData={analysisTabData.report.investment_readiness_analysis["Exit Strategy"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Funding and Capital Requirements"]) && (
                <div ref={analysisTabRefs.fundingandCapitalRequirementsAnalysis}>
                    <FundingandCapitalRequirements fundingandCapitalRequirementsSectionData={analysisTabData.report.investment_readiness_analysis["Funding and Capital Requirements"]} assessment={assessment_data_submitted_by_the_business} />
                </div>
            )}

            {/* <div ref={analysisTabRefs.strengthAndWeakness}>
                <StrengthAndWeakness strengthAndWeaknessSectionData={analysisTabData} />
            </div>
            <div ref={analysisTabRefs.recommendations}>
                <Recommendations recommendationsSectionData={analysisTabData} />
            </div> */}

        </div>
    );
};

export default AssessmentAnalysisContent;