import React from "react";
import SectionContent from "../sectionComponents/SectionContent";
import SectionTitle from "../sectionComponents/SectionTitle";

const FinancialMetrics = ({ financialMetricsSectionData, assessment }) => {
    const sample_section_estimates = "The World Agricultural Supply and Demand Estimates (WASDE) is prepared and released by the World Agricultural Outlook Board (WAOB). The report is released monthly, and provides annual forecasts for supply and use of U.S. and world wheat, rice, coarse grains, oilseeds, and cotton. ";
    const section_sample_content = "Lebanon’s health ministry says 492 killed, including 35 children and 58 women. More than 1,600 wounded. Israel says 1,300 Hezbollah assets struck. The report also covers U.S. supply and use of sugar, meat, poultry eggs and milk, as well as Mexico’s supply and use of sugar. The WAOB chairs the Interagency Commodity Estimates Committees (ICECs), which include analysts from key USDA agencies who compile and interpret information from USDA and other domestic and foreign sources to produce the report. For more information about the WASDE process and data, visit the WASDE FAQs page.";

    let blue_box_data;
    let blue_box_data_list;
    let blue_box_title;
    let blue_box_description;

    let green_box_data;
    let green_box_data_list;
    let green_box_title;
    let green_box_description;

    let second_blue_box_data;
    let second_blue_box_data_list;
    let second_blue_box_title;
    let second_blue_box_description;

    let second_green_box_data;
    let second_green_box_data_list;
    let second_green_box_title;
    let second_green_box_description;

    let yellow_box_data;
    let yellow_box_data_list;
    let yellow_box_title;
    let yellow_box_description;

    let second_yellow_box_data;
    let second_yellow_box_data_list;
    let second_yellow_box_title;
    let second_yellow_box_description;

    let analysisSectionTextDescription;

    const formatCurrency = (numberString) => {
        const number = parseInt(numberString, 10); // Convert string to number
        if (isNaN(number)) {
            return ""; // Return error message if input is not a valid number
        }
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            // Uncomment the following line to display compact notation for large numbers
            // notation: 'compact',
            minimumFractionDigits: 0,  // No fractional part for whole numbers
        });

        return formatter.format(number);
    };

    if (Array.isArray(financialMetricsSectionData)) {
        blue_box_data = financialMetricsSectionData[0] ? financialMetricsSectionData[0] : "Metric not Available";
        blue_box_data_list = blue_box_data !== "" ? blue_box_data.split(":") : null;
        blue_box_title = blue_box_data_list !== null ? blue_box_data_list[0] : "Sales Cycle";
        blue_box_description = blue_box_data_list !== null ? blue_box_data_list[1] : "Metric Value not Available";

        green_box_data = financialMetricsSectionData[1] ? financialMetricsSectionData[1] : "Metric not Available";
        green_box_data_list = green_box_data !== "" ? green_box_data.split(":") : null;
        green_box_title = green_box_data_list !== null ? green_box_data_list[0] : "CAC vs. CLTV";
        green_box_description = green_box_data_list !== null ? green_box_data_list[1] : "Metric Value not Available";

        second_blue_box_data = financialMetricsSectionData[2] ? financialMetricsSectionData[2] : "Metric not Available";
        second_blue_box_data_list = second_blue_box_data !== "" ? second_blue_box_data.split(":") : null;
        second_blue_box_title = second_blue_box_data_list !== null ? second_blue_box_data_list[0] : "Monthly Revenue";
        second_blue_box_description = second_blue_box_data_list !== null ? second_blue_box_data_list[1] : "Metric Value not Available";

        second_green_box_data = financialMetricsSectionData[3] ? financialMetricsSectionData[3] : "Metric not Available";
        second_green_box_data_list = second_green_box_data !== "" ? second_green_box_data.split(":") : null;
        second_green_box_title = second_green_box_data_list !== null ? second_green_box_data_list[0] : "Break-even";
        second_green_box_description = second_green_box_data_list !== null ? second_green_box_data_list[1] : "Metric Value not Available";

        analysisSectionTextDescription = financialMetricsSectionData[2] ? financialMetricsSectionData[2] : "Analysis is not available at this moment for this metric.";
    }
    else if (typeof financialMetricsSectionData === "object" && financialMetricsSectionData !== null && !Array.isArray(financialMetricsSectionData)) {
        blue_box_title = "CAC Vs LTV";
        // blue_box_description = financialMetricsSectionData["Customer Acquisition Cost (CAC)"] || financialMetricsSectionData["CAC (Customer Acquisition Cost)"];
        blue_box_description = assessment?.customer_acquisition_cost || "";

        green_box_title = "Average monthly revenue";
        // green_box_description = financialMetricsSectionData["Customer Lifetime Value (CLTV)"] || financialMetricsSectionData["CLTV (Customer Lifetime Value)"];
        green_box_description = formatCurrency(assessment?.average_monthly_revenue || "");

        yellow_box_title = "Revenue models";
        // yellow_box_description = financialMetricsSectionData["Break-even Expectation"] || financialMetricsSectionData["Break-even Timeline"];
        yellow_box_description = assessment?.ways_business_generate_revenue || "";

        second_blue_box_title = "Break-even Expectations";
        // second_blue_box_description = financialMetricsSectionData["Sales Cycle"];
        second_blue_box_description = assessment?.break_even_projection || "";

        second_green_box_title = "Projected Annual Growth Rate (PAGR)";
        // second_green_box_description = financialMetricsSectionData["Projected Annual Growth Rate (PAGR)"];
        second_green_box_description = assessment?.projected_annual_growth_five_years || "";

        second_yellow_box_title = "Business plan";
        // second_green_box_description = financialMetricsSectionData["Projected Annual Growth Rate (PAGR)"];
        second_yellow_box_description = assessment?.have_business_plan || "";

        analysisSectionTextDescription = financialMetricsSectionData["Analysis"];
    } else {
        blue_box_title = "Sales Cycle";
        blue_box_description = "Metric Value not Available";
        green_box_title = "CAC vs. CLTV";
        green_box_description = "Metric Value not Available";
        second_blue_box_title = "Monthly Revenue";
        second_blue_box_description = "Metric Value not Available";
        second_green_box_title = "Break-even";
        second_green_box_description = "Metric Value not Available";
        analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    }

    return (
        <div className="sectionContainer">
            <SectionTitle sectionTitle="Financial Metrics" />
            <SectionContent
                sectionEstimates={sample_section_estimates}
                sectionContent={section_sample_content}
                blue_box_title={blue_box_title}
                blue_box_description={blue_box_description}
                green_box_title={green_box_title}
                green_box_description={green_box_description}
                second_blue_box_title={second_blue_box_title}
                second_blue_box_description={second_blue_box_description}
                second_green_box_title={second_green_box_title}
                second_green_box_description={second_green_box_description}
                yellow_box_title={yellow_box_title}
                yellow_box_description={yellow_box_description}
                second_yellow_box_title={second_yellow_box_title}
                second_yellow_box_description={second_yellow_box_description}
                analysisSectionTextDescription={analysisSectionTextDescription}
                comingFrom="Financial Metrics"
            />
        </div>
    );
};

export default FinancialMetrics;