import React from "react";
import CompanyOverviewForInvestmentReadiness from "./CompanyOverviewForInvestmentReadiness";
import MarketOpportunityComponentForInvestmentReadiness from "./MarketOpportunityComponentForInvestmentReadiness";
import CompetitiveLandscapeForInvestmentReadiness from "./CompetitiveLandscapeForInvestmentReadiness";
import ProductAndDevelopmentStageForInvestmentReadiness from "./ProductAndDevelopmentStageForInvestmentReadiness";
import FInancialMetricsForInvestmentReadiness from "./FInancialMetricsForInvestmentReadiness";
import TractionAndMarketPenetrationForInvestmentReadiness from "./TractionAndMarketPenetrationForInvestmentReadiness";
import RiskAnalysisForInvestmentReadiness from "./RiskAnalysisForInvestmentReadiness";
import TeamExperienceForInvestmentReadiness from "./TeamExperienceForInvestmentReadiness";
import GovernanceAndSupportForInvestmentReadiness from "./GovernanceAndSupportForInvestmentReadiness";
import RevenueModelAndGrowthForInvestmentReadiness from "./RevenueModelAndGrowthForInvestmentReadiness";
import OperationalEfficiencyForInvestmentReadiness from "./OperationalEfficiencyForInvestmentReadiness";
import RecommendationAndImprovementsForInvestmentReadiness from "./RecommendationAndImprovementsForInvestmentReadiness";
import ConclusionForInvestmentReadiness from "./ConclusionForInvestmentReadiness";
import InvestmentOpportunityForInvestmentReadiness from "./InvestmentOpportunityForInvestmentReadiness";
import FundingandCapitalRequirementsForInvestmentReadiness from "./FundingandCapitalRequirementsForInvestmentReadiness";

import "../../../css/AssessmentInvestmentReadinessContent.css";

const AssessmentInvestmentReadinessContent = ({
    parentStateForActiveTab,
    analysisTabRefs,
    analysisTabData,
    executableSolutionsTabData,
    benchmarksTabData,
    investmentReadinessTabData,
    completeReportData
}) => {

    return (
        <div className="investmentReadinessContent">
            <div ref={analysisTabRefs.companyOverview}>
                <CompanyOverviewForInvestmentReadiness completeReportData={completeReportData} summary={investmentReadinessTabData?.report?.investment_readiness_report?.["Executive Summary"]?.["summary"]} />
            </div>

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Market Opportunity"]) && (
                <div ref={analysisTabRefs.marketOpportunity}>
                    <MarketOpportunityComponentForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Market Opportunity"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Market Opportunity"] || {}}
                        rankingData={benchmarksTabData?.report?.["Market Opportunity"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}

            {(investmentReadinessTabData?.report?.investment_readiness_report?.["Remediation"]?.["Competitive Landscape"]) && (
                <div ref={analysisTabRefs.competitiveLandscape}>
                    <CompetitiveLandscapeForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Competitive Landscape"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Competitive Landscape"]}
                        rankingData={benchmarksTabData?.report?.["Competitive Landscape"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}

            {(investmentReadinessTabData?.report?.investment_readiness_report?.["Remediation"]?.["Product and Development"]) && (
                <div ref={analysisTabRefs.productAndDevelopmentStage}>
                    <ProductAndDevelopmentStageForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Product and Development"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Product and Development"]}
                        rankingData={benchmarksTabData?.report?.["Product and Development"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}

            {(investmentReadinessTabData?.report?.investment_readiness_report?.["Remediation"]?.["Financial Metrics"]) && (
                <div ref={analysisTabRefs.financialMetrics}>
                    <FInancialMetricsForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Financial Metrics"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Financial Metrics"]}
                        rankingData={benchmarksTabData?.report?.["Financial Metrics"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}

            {(investmentReadinessTabData?.report?.investment_readiness_report?.["Remediation"]?.["Traction and Market Penetration"]) && (
                <div ref={analysisTabRefs.tractionAndMarketPenetration}>
                    <TractionAndMarketPenetrationForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Traction and Market Penetration"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Traction and Market Penetration"]}
                        rankingData={benchmarksTabData?.report?.["Traction and Market Penetration"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}

            {(investmentReadinessTabData?.report?.investment_readiness_report?.["Remediation"]?.["Risk Analysis"]) && (
                <div ref={analysisTabRefs.riskAnalysis}>
                    <RiskAnalysisForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Risk Analysis"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Risk Analysis"]}
                        rankingData={benchmarksTabData?.report?.["Risk Analysis"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}

            {(investmentReadinessTabData?.report?.investment_readiness_report?.["Remediation"]?.["Team and Experience"]) && (
                <div ref={analysisTabRefs.teamAndExperience}>
                    <TeamExperienceForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Team and Experience"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Team and Experience"]}
                        rankingData={benchmarksTabData?.report?.["Team and Experience"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}

            {(investmentReadinessTabData?.report?.investment_readiness_report?.["Remediation"]?.["Governance and Support"]) && (
                <div ref={analysisTabRefs.governanceAndSupport}>
                    <GovernanceAndSupportForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Governance and Support"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Governance and Support"]}
                        rankingData={benchmarksTabData?.report?.["Governance and Support"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}

            {(investmentReadinessTabData?.report?.investment_readiness_report?.["Remediation"]?.["Revenue Model and Growth"]) && (
                <div ref={analysisTabRefs.revenueModelAndGrowth}>
                    <RevenueModelAndGrowthForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Revenue Model and Growth"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Revenue Model and Growth"]}
                        rankingData={benchmarksTabData?.report?.["Revenue Model and Growth"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}

            {(investmentReadinessTabData?.report?.investment_readiness_report?.["Remediation"]?.["Exit Strategy"]) && (
                <div ref={analysisTabRefs.operationEfficiency}>
                    <OperationalEfficiencyForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Exit Strategy"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Exit Strategy"]}
                        rankingData={benchmarksTabData?.report?.["Exit Strategy"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}

            {(investmentReadinessTabData?.report?.investment_readiness_report?.["Remediation"]?.["Funding and Capital Requirements"]) && (
                <div ref={analysisTabRefs.fundingAndCapitalRequirements}>
                    <FundingandCapitalRequirementsForInvestmentReadiness
                        sectionData={investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Funding and Capital Requirements"]}
                        analysis={analysisTabData?.report?.investment_readiness_analysis?.["Funding and Capital Requirements"]}
                        rankingData={benchmarksTabData?.report?.["Funding and Capital Requirements"]?.["ranking"]}
                        completeReportData={completeReportData}
                    />
                </div>
            )}


            {/* <div ref={analysisTabRefs.recommendations}>
                <RecommendationAndImprovementsForInvestmentReadiness />
            </div>

            <div ref={analysisTabRefs.investmentReadinessConclusion}>
                <ConclusionForInvestmentReadiness />
            </div>

            <div ref={analysisTabRefs.investmentOpportunity}>
                <InvestmentOpportunityForInvestmentReadiness />
            </div> */}

        </div>
    );
};

export default AssessmentInvestmentReadinessContent;