import React, { useEffect } from "react";
import MainNavbarForDashboard from "../navbarComponents/NavbarForMainDashbaord";
import InboxContainer from "./InboxContainer";
import UserChatInterface from "./UserChatInterface";
import NoMailsHeaderComponent from "../NoMailsHeaderComponent";

import "../../css/inboxContainerBelowNavbar.css";

const isNavbarForDashboard = true;
const MessagingPortal = () => {

    // Disable scroll when this component is mounted
    useEffect(() => {
        document.body.style.overflow = 'hidden'; // Disable scrolling
        document.body.style.height = '100vh';    // Ensure height is full screen

        // Cleanup: Enable scroll again when the component unmounts
        return () => {
            document.body.style.overflow = 'auto'; // Restore scrolling
            document.body.style.height = 'auto';   // Reset height
        };
    }, []);

    const user_details = JSON.parse(sessionStorage.getItem("user_details"));
    // console.log("in main dashboard page where the logged_in_user_id = ", user_details);

    const headerData = {
        title: "Welcome to Anvil List, your trusted partner on the path to investment readiness.",
        subTitle: "For the best experience, we recommend taking our assessment and setting up your profile. This will help us identify the gaps in your business and work towards closing them."
    };

    return (
        <div className="completePageContainerForTheMessagingPortal">
            <MainNavbarForDashboard
                isNavbarForDashboard={isNavbarForDashboard}
                user_details={user_details}
                current_tab="mailIcon"
            />
            <div className="mainContentBelowTheNavbarForTheMessagingPortal" style={{marginTop: "5rem"}}>
                {/* <InboxContainer />  */}
                {/* {user_details.logged_in_user_id === "5d93fe35-e62c-4e65-9418-3944a5e97603" && <NoMailsHeaderComponent/>} */}
                {user_details.logged_in_user_id && <UserChatInterface user_details={user_details} />}
                {/* <NoMailsHeaderComponent/> */}
                {/* <SampleCheck /> */}

            </div>
        </div>
    )

};

export default MessagingPortal;
