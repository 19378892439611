import React, { useState } from "react";

const AssessmentReportSectionNavigationComponent = ({ parentStateForActiveTab, analysisTabRefs, analysisTabData }) => {
    const [activeSection, setActiveSection] = useState("Market Opportunity");

    const handleClickAssessmentReportAnalysisNavigationItem = (sectionName, ref) => {
        setActiveSection(sectionName);  // Set the active section
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="listOfAssessmentReportSections">

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Market Opportunity"]) && (
                <div
                    className={(activeSection === "Market Opportunity") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Market Opportunity", analysisTabRefs.marketOpportunity)}
                >
                    Market Opportunity
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Competitive Landscape"]) && (
                <div
                    className={(activeSection === "Competitive Landscape") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Competitive Landscape", analysisTabRefs.competitiveLandscape)}
                >
                    Competitive Landscape
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Product and Development"]) && (
                <div
                    className={(activeSection === "Product and Development") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Product and Development", analysisTabRefs.productAndDevelopmentStage)}
                >
                    Product and Development
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Financial Metrics"]) && (
                <div
                    className={(activeSection === "Financial Metrics") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Financial Metrics", analysisTabRefs.financialMetrics)}
                >
                    Financial Metrics
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Traction and Market Penetration"]) && (
                <div
                    className={(activeSection === "Traction and Market Penetration") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Traction and Market Penetration", analysisTabRefs.tractionAndMarketPenetration)}
                >
                    Traction and Market Penetration
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Risk Analysis"]) && (
                <div
                    className={(activeSection === "Risk Analysis") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Risk Analysis", analysisTabRefs.riskAnalysis)}
                >
                    Risk Analysis
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Team and Experience"]) && (
                <div
                    className={(activeSection === "Team and Experience") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Team and Experience", analysisTabRefs.teamAndExperience)}
                >
                    Team and Experience
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Governance and Support"]) && (
                <div
                    className={(activeSection === "Governance and Support") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Governance and Support", analysisTabRefs.governanceAndSupport)}
                >
                    Governance and Support
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Revenue Model and Growth"]) && (
                <div
                    className={(activeSection === "Revenue Model and Growth") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Revenue Model and Growth", analysisTabRefs.revenueModelAndGrowth)}
                >
                    Revenue Model and Growth
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Exit Strategy"]) && (
                <div
                    className={(activeSection === "Exit Strategy") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Exit Strategy", analysisTabRefs.exitStratergy)}
                >
                    Exit Strategy
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report.investment_readiness_analysis && analysisTabData.report.investment_readiness_analysis["Funding and Capital Requirements"]) && (
                <div
                    className={(activeSection === "Funding and Capital Requirements") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Funding and Capital Requirements", analysisTabRefs.fundingandCapitalRequirementsAnalysis)}
                >
                    Funding and Capital Requirements
                </div>
            )}

            {/* <div
                className={(activeSection === "Strengths & Weaknesses") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Strengths & Weaknesses", analysisTabRefs.strengthAndWeakness)}
            >
                Strengths & Weaknesses
            </div>
            <div
                className={(activeSection === "Recommendations") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Recommendations", analysisTabRefs.recommendations)}
            >
                Recommendations
            </div> */}


        </div>
    );
};

export default AssessmentReportSectionNavigationComponent;