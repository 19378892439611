import React, { useState } from "react";

const AssessmentReportSectionNavigationComponentForExecutableSolutions = ({ parentStateForActiveTab, analysisTabRefs, executableSolutionsTabData = {} }) => {
    const [activeSection, setActiveSection] = useState("Market Opportunity");

    const handleClickAssessmentReportAnalysisNavigationItem = (sectionName, ref) => {
        setActiveSection(sectionName);  // Set the active section
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="listOfAssessmentReportSections">
            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Market Opportunity"]) && (
                <div
                    className={(activeSection === "Market Opportunity") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Market Opportunity", analysisTabRefs.marketOpportunity)}
                >
                    Market Opportunity
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Competitive Landscape"]) && (
                <div
                    className={(activeSection === "Competitive Landscape") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Competitive Landscape", analysisTabRefs.competitiveLandscape)}
                >
                    Competitive Landscape
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Product and Development"]) && (
                <div
                    className={(activeSection === "Product and Development") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Product and Development", analysisTabRefs.productAndDevelopmentStage)}
                >
                    Product and Development
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Financial Metrics"]) && (
                <div
                    className={(activeSection === "Financial Metrics") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Financial Metrics", analysisTabRefs.financialMetrics)}
                >
                    Financial Metrics
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Traction and Market Penetration"]) && (
                <div
                    className={(activeSection === "Traction and Market Penetration") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Traction and Market Penetration", analysisTabRefs.tractionAndMarketPenetration)}
                >
                    Traction and Market Penetration
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Risk Analysis"]) && (
                <div
                    className={(activeSection === "Risk Analysis") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Risk Analysis", analysisTabRefs.riskAnalysis)}
                >
                    Risk Analysis
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Team and Experience"]) && (
                <div
                    className={(activeSection === "Team and Experience") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Team and Experience", analysisTabRefs.teamAndExperience)}
                >
                    Team and Experience
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Governance and Support"]) && (
                <div
                    className={(activeSection === "Governance and Support") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Governance and Support", analysisTabRefs.governanceAndSupport)}
                >
                    Governance and Support
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Revenue Model and Growth"]) && (
                <div
                    className={(activeSection === "Revenue Model and Growth") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Revenue Model and Growth", analysisTabRefs.revenueModelAndGrowth)}
                >
                    Revenue Model and Growth
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Exit Strategy"]) && (
                <div
                    className={(activeSection === "Exit Strategy") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Exit Strategy", analysisTabRefs.exitStratergy)}
                >
                    Exit Strategy
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Funding and Capital Requirements"]) && (
                <div
                    className={(activeSection === "Funding and Capital Requirements") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Funding and Capital Requirements", analysisTabRefs.fundingAndCapitalRequirements)}
                >
                    Funding and Capital Requirements
                </div>
            )}

            {/* <div
                className={(activeSection === "Conclusion") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Conclusion", analysisTabRefs.conclusion)}
            >
                Conclusion
            </div> */}


        </div>
    );
};

export default AssessmentReportSectionNavigationComponentForExecutableSolutions;