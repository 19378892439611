import React from "react";
import belowAverageMedalIcon from "../../../assets/belowAverageRankingIcon.png";
import averageMedalIcon from "../../../assets/averageRankingIcon.png";
import aboveAverageRankingIcon from "../../../assets/aboveAverageRankingIcon.png";
import strengthIconForBenchmark from "../../../assets/strengthIconForBenchmark.png";
import bulbIconForIndustryStandard from "../../../assets/bulbIconForIndustryStandard.png";
import BenchmarkHowToGetThereContainers from "./BenchmarkHowToGetThereContainers";
import {
    safeGetFirstPart,
    safeGetSecondPart,
} from "./BenchmarksSectionHelperFunctions";

const ProductAndDevelopmentStageForBenchmark = ({ sectionData }) => {
    const current_state_first_value =
        sectionData &&
            sectionData["company_standard"] &&
            sectionData["company_standard"]["Product Diversification"]
            ? sectionData["company_standard"]["Product Diversification"]
            : "";
    const industry_standard_first_value =
        sectionData &&
            sectionData["industry_standard"] &&
            sectionData["industry_standard"]["Product Diversification"]
            ? sectionData["industry_standard"]["Product Diversification"]
            : "";
    const section_ranking =
        sectionData && sectionData["ranking"] ? sectionData["ranking"] : "";
    const section_recommendation =
        sectionData && sectionData["recommendation"]
            ? sectionData["recommendation"]
            : "";

    var section_ranking_font_color = "";
    if (section_ranking === "Below Average") {
        section_ranking_font_color = "belowAvergaeRankingDescription";
    } else if (section_ranking === "Average") {
        section_ranking_font_color = "avergaeRankingDescription";
    } else if (section_ranking === "Above Average") {
        section_ranking_font_color = "aboveAverageRankingDescription";
    } else {
        section_ranking_font_color = "defaultTextColor";
    }

    var rankingBorderClass = "";
    if (section_ranking === "Below Average") {
        rankingBorderClass = "belowAvergaeRanking";
    } else if (section_ranking === "Average") {
        rankingBorderClass = "avergaeRanking";
    } else if (section_ranking === "Above Average") {
        rankingBorderClass = "aboveAverageRanking";
    } else {
        rankingBorderClass = "";
    }

    return (
        <div className="benchmarkSectionContainer">
            <div className="sectionTitleAndBenchmarkEvalution">
                <div className="benchmarkSectionHeader">
                    03 Product and Development Stage
                </div>
                {section_ranking != "" && (
                    <div className={`benchmarkEvaluationRanking ${rankingBorderClass}`}>
                        <div className="medalIcon">
                            {section_ranking == "Below Average" && (
                                <img
                                    src={belowAverageMedalIcon}
                                    width="44px"
                                    height="44px"
                                    alt="Below Average Icon"
                                />
                            )}
                            {section_ranking == "Average" && (
                                <img
                                    src={averageMedalIcon}
                                    width="44px"
                                    height="44px"
                                    alt="Average Icon"
                                />
                            )}
                            {section_ranking == "Above Average" && (
                                <img
                                    src={aboveAverageRankingIcon}
                                    width="44px"
                                    height="44px"
                                    alt="Above Average Icon"
                                />
                            )}
                        </div>
                        <div className="rakingTitleAndDescription">
                            <div className="rankingTitleName">Ranking</div>
                            <div
                                className={`rankingDescription ${section_ranking_font_color}`}
                            >
                                {section_ranking}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="anvlistAndindustryStandardContentContainer">
                <div className="anvlistContainer">
                    <img
                        src={strengthIconForBenchmark}
                        width="30px"
                        height="25px"
                        alt="Anvilist Icon"
                    />
                    <div className="anvilistTitle">Company Standard</div>
                    <div className="anvilistDescription">
                        <div className="competitorsDescriptionContent">
                            {/* <div className="competitorsContentKey">{safeGetFirstPart(current_state_first_value)}</div>
                            <div className="competitorsContentDescription">{safeGetSecondPart(current_state_first_value)}</div> */}
                            <div className="competitorsContentKey">
                                Product Diversification : 
                            </div>
                            <div className="competitorsContentDescription">
                                {current_state_first_value}
                            </div>
                        </div>
                        {/* <div className="differentiatorsDescriptionContent">
                            <div className="differentiatorsContentKey">{safeGetFirstPart(current_state_second_value)}</div>
                            <div className="competitorsContentDescription">{safeGetSecondPart(current_state_second_value)}</div>
                        </div> */}
                    </div>
                </div>
                <div className="industryStandardContainer">
                    <img
                        src={bulbIconForIndustryStandard}
                        width="30px"
                        height="25px"
                        alt="Industry Standard Icon"
                    />
                    <div className="industryStandardTitle">Industry Standard</div>
                    <div className="industryStandardDescription">
                        <div className="competitorsDescriptionContent">
                            {/* <div className="competitorsContentKey">{safeGetFirstPart(industry_standard_first_value)}</div>
                            <div className="competitorsContentDescription">{safeGetSecondPart(industry_standard_first_value)}</div> */}
                            <div className="competitorsContentKey">
                                Product Diversification : 
                            </div>
                            <div className="competitorsContentDescription">
                                {industry_standard_first_value}
                            </div>
                        </div>
                        {/* <div className="differentiatorsDescriptionContent">
                            <div className="differentiatorsContentKey">{safeGetFirstPart(industry_standard_second_value)}</div>
                            <div className="competitorsContentDescription">{safeGetSecondPart(industry_standard_second_value)}</div>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="howToGetThereHeader">How to get there ?</div>
            <div className="howToGetThereContentContainer">
                {/* {recommendationPoints.map((point, index) => (
                    <BenchmarkHowToGetThereContainers key={index} pointIndex={index} pointDescription={point} />
                ))} */}
                <BenchmarkHowToGetThereContainers
                    pointDescription={section_recommendation}
                />
            </div>
        </div>
    );
};

export default ProductAndDevelopmentStageForBenchmark;
