import React from "react";
import MainScreen from "../components/MainScreen";
import MainNavbarForDashboard from "../components/navbarComponents/NavbarForMainDashbaord";
import "../css/dashboard.css";
import { useLocation } from "react-router-dom";

const isNavbarForDashboard = true;

const Dashboard = () => {
  // const location = useLocation();
  // const state_from_login = location.state || {};
  // console.log(state_from_login)
  const user_details = JSON.parse(sessionStorage.getItem("user_details"));
  // const logged_in_user_id = state_from_login.user_details.logged_in_user_id != undefined ? state_from_login.user_details.logged_in_user_id : sessionStorage.getItem("loggedInUserID");
  // console.log("in dashboard logged_in_user_id = ", user_details);
  // const user_name = user_details.user_name || "Unknown User";
  // const user_access_token = user_details.user_access_token || "";

  return (
    <div className="completePageContainer">
      <MainNavbarForDashboard
        isNavbarForDashboard={isNavbarForDashboard}
        user_details={user_details}
        current_tab="start"
      />
      <div className="mainContentBelowTheNavbar" style={{marginTop: "4rem"}}>
        {/* <MainScreen logged_in_user_id = {logged_in_user_id} user_access_token={user_access_token} user_name={user_name}/> */}
        <MainScreen user_details={user_details} />
      </div>
    </div>
  );
};


export default Dashboard;
