import React, { useState, useEffect, useCallback } from "react";
import SignUpPageHeaderComponent from "../components/signUpPageComponents/SignUpPageHeaderComponent";
import AccountCreationComponent from "../components/signUpPageComponents/AccountCreationComponent";
import ProgressStepComponent from "../components/signUpPageComponents/ProgressStepComponent";
import SignupFormContainerComponent from "../components/signUpPageComponents/SignUpFormContainerComponent";
import { useNavigate } from "react-router-dom";
import EmailValidation from "../formValidations/emailValidation";
import PasswordValidationComponent from "../formValidations/passwordValidations";
import PhoneNumberValidator from "../formValidations/phoneNumberValidator";
import { Form, notification } from "antd";
import apiDetails from "../assets/apiDetails/apiDetails.json";
import "../css/signUpPage.css";

const SignUpPageComponent = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [errorVisible, setErrorVisible] = useState(false);
    let defaultErrorMessage = "Please fill in all the fields before you continue to the next step";
    const [formFieldErrorMessage, setFormFieldErrorMessage] = useState(defaultErrorMessage);
    const [form] = Form.useForm();  // Use the Ant Design Form instance
    const [fieldErrors, setFieldErrors] = useState({}); // state for field Errors
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [dropdownOptionsFromIndustryApi, setDropdownOptions] = useState([]); // State to store fetched API data

    const [apiBody, setApiBody] = useState({
        email: "kofi@email.com",
        name: "Kofi Doe",
        phone: "0209386780",
        business_name: "Hanish Company Ltd",
        type: "business",
        password: "password",
        industry: null
    });

    // useEffect to run only once on component mount
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url_domain = apiDetails.domain;
                const version_path = apiDetails.version_path;
                const industries = "industries";
                const fetch_url = `${url_domain}/${version_path}/${industries}`;
                const response = await fetch(fetch_url, {
                    method: 'GET', // Specifying the HTTP method
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                // console.log("data from the dropdown options is ");
                // console.log(data);
                setDropdownOptions(data); // Set the fetched data into state
            } catch (error) {
                // console.error("Failed to fetch data:", error);
                // notification.error({
                //     message: 'Error',
                //     description: 'Failed to load data from API',
                // });
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const openErrorNotification = (notificationParameters) => {
        // console.log("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Registration Unsuccessfull',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
        setCurrentStep(0);
    };

    const [shouldProceed, setShouldProceed] = useState(false);
    const navigate = useNavigate();
    const signUpPageComponentHeaderData = {
        "title": "Create an account",
        "subtitle": "Already have an account? ",
        "coming_from": "signup"
    }

    let formItems = [
        { label: "What is your email?", rules: [{ required: true, message: 'Please enter your email' }] },
        { label: "What is your first name?", rules: [{ required: true, message: 'Please enter your first name' }] },
        { label: "What is your last name?", rules: [{ required: true, message: 'Please enter your last name' }] },
        { label: "What is your phone number?", rules: [{ required: true, message: 'Please enter your phone number' }] },
        { label: "What is your business name?", rules: [{ required: true, message: 'Please enter your business name' }] },
        { label: "What industry is your business in?", rules: [{ required: true, message: 'Please enter the industry in which your business is in' }] },
        // { label: "What industry does your business belong to?", rules: [{ required: true, message: 'Please enter your business industry' }] },
        { label: "Create your password", rules: [{ required: true, message: 'Please create your password' }] },
        { label: "Confirm password", rules: [{ required: true, message: 'Please confirm your password' }] },
    ];

    const emailKey = "What is your email?";
    const firstName = "What is your first name?";
    const lastName = "What is your last name?";
    const businessName = "What is your business name?";
    const phoneNumber = "What is your phone number?";
    const businessIndustryName = "What industry is your business in?";
    const createPassword = "Create your password";
    const confirmPassword = "Confirm password";

    const validateEmptyFields = (values) => {
        // new variable for maintaining all the error messages
        let errors = {};
        let isThereAFormError = true;

        if (currentStep === 0) {

            const userEmail = values[emailKey];
            if (userEmail === null || userEmail === undefined || userEmail.length === 0) {
                errors[emailKey] = 'Email is required';
                isThereAFormError = false;
                //return false;
            }

            // checking the pattern for email validation
            if (!EmailValidation(null, userEmail)) {
                setFormFieldErrorMessage("Please enter a valid email address");
                errors[emailKey] = 'Please enter a valid email address';
                isThereAFormError = false;
                // return false;
            }

            setFormFieldErrorMessage(defaultErrorMessage);
            // updateApiBody("email", userEmail);
        }

        else if (currentStep === 1) {
            const userFirstName = values[firstName];
            if (userFirstName === null || userFirstName === undefined || userFirstName.length === 0) {
                errors[firstName] = "Please enter your first name";
                isThereAFormError = false;
                //return false;
            }

            const userLastName = values[lastName];
            if (userLastName === null || userLastName === undefined || userLastName.length === 0) {
                errors[lastName] = "Please enter your last name";
                isThereAFormError = false;
                // return false;
            }

            const userPhoneNumber = values[phoneNumber];
            if (userPhoneNumber === null || userPhoneNumber === undefined || userPhoneNumber.length === 0) {
                errors[phoneNumber] = "Please enter your phone number";
                isThereAFormError = false;
                // return false;
            }

            if (!PhoneNumberValidator(userPhoneNumber)) {
                errors[phoneNumber] = "Please enter a valid phone number";
                isThereAFormError = false;
                // return false;
                //setFormFieldErrorMessage("Enter a valid phone number");
                // return false;
            }
            setFormFieldErrorMessage(defaultErrorMessage);

            const userBusinessName = values[businessName];
            if (userBusinessName === null || userBusinessName === undefined || userBusinessName.length === 0) {
                errors[businessName] = "Please enter your business name";
                isThereAFormError = false;
                // return false;
            }
        }

        else if (currentStep === 2) {
            const userCreatePassword = values[createPassword];
            if (userCreatePassword === null || userCreatePassword === undefined || userCreatePassword.length === 0) {
                errors[createPassword] = "Please enter your password";
                isThereAFormError = false;
                // return false;
            }
            const passwordValidationCheck = PasswordValidationComponent(userCreatePassword);
            if (!passwordValidationCheck[0]) {
                errors[createPassword] = passwordValidationCheck[1];
                isThereAFormError = false;
                // setFormFieldErrorMessage(passwordValidationCheck[1]);
                // return false;
            }
            setFormFieldErrorMessage(defaultErrorMessage);

            const userConfirmPassword = values[confirmPassword];
            if (userConfirmPassword === null || userConfirmPassword === undefined || userConfirmPassword.length === 0) {
                errors[confirmPassword] = "Please enter your password to confirm";
                isThereAFormError = false;
                // return false
            }

            if (userConfirmPassword !== userCreatePassword) {
                errors[confirmPassword] = "Passwords do not Match";
                isThereAFormError = false;
                // setFormFieldErrorMessage("Passwords do not Match");
                // return false;
            }
            setFormFieldErrorMessage(defaultErrorMessage);
        }

        setFieldErrors(errors);
        return isThereAFormError;
        // return true;
    };

    const handleFormFinish = async (values) => {
        if (!validateEmptyFields(values)) {
            // setErrorVisible(true);
            return;
        }
        updateApiBody(values);
        setShouldProceed(true);
        // console.log("apiBody = ", apiBody);
    };

    // Memoize registerUserBusiness
    const registerUserBusiness = useCallback(() => {
        setIsLoading(true); // Start loading before the fetch
        const url_domain = apiDetails.domain;
        const version_path = apiDetails.version_path;
        const register = "register";
        const fetch_url = `${url_domain}/${version_path}/${register}`;
        fetch(fetch_url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apiBody)
        })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    // console.error('Registration failed:', errorData);
                    openErrorNotification(errorData);
                    throw new Error('Failed to register');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Registration successful:', data);
                // navigate("/login");
                navigate("/verify-account");
            })
            .catch(error => {
                // console.error('An error occurred:', error);
            })
            .finally(() => {
                setIsLoading(false); // Stop loading regardless of the result
            });
    }, [apiBody, navigate]); // Include dependencies that could change

    const updateApiBody = (values) => {
        if (currentStep === 0) {
            setApiBody(prevState => ({
                ...prevState,
                email: values[emailKey],
            }));
        } else if (currentStep === 1) {
            let full_name = values[firstName] + " " + values[lastName];
            setApiBody(prevState => ({
                ...prevState,
                name: full_name,
                business_name: values[businessName],
                phone: values[phoneNumber],
                industry: values[businessIndustryName]
            }));
        } else if (currentStep === 2) {
            setApiBody(prevState => ({
                ...prevState,
                password: values[createPassword],
            }));
        }
    };

    const onStepsChange = (value) => {
        const currentValues = form.getFieldsValue();
        // console.log('currentValues:', currentValues);
        if (value > currentStep) {
            if (!validateEmptyFields(currentValues)) {
                // setErrorVisible(true);
                return;
            }
        }
        // setErrorVisible(false);
        updateApiBody(currentValues);
        // console.log('onStepsChange:', value);
        setCurrentStep(value);
    };

    // Determine which form items to display based on the current step
    let itemsToShow = [];
    let buttonText = 'Next';
    if (currentStep === 0) {
        itemsToShow = [formItems[0]]; // Show email field
    } else if (currentStep === 1) {
        itemsToShow = formItems.slice(1, 6); // Show first name, last name, business name, and industry
    } else {
        itemsToShow = formItems.slice(6, 8); // Show password and confirm password fields
        buttonText = 'Create My Account'
    }

    useEffect(() => {
        if (shouldProceed) {
            if (currentStep < 2) {
                setCurrentStep(currentStep + 1);
            } else {
                // console.log("apiBodyForUserRegistration", apiBody);
                registerUserBusiness();
            }
            // setErrorVisible(false);
            setShouldProceed(false); // Reset the flag
        }
    }, [shouldProceed, currentStep, apiBody, registerUserBusiness]); // Run when shouldProceed or currentStep changes


    return (
        <div className="signUpPageContainer">
            {contextHolder}
            <SignUpPageHeaderComponent />
            <AccountCreationComponent componentHeaderData={signUpPageComponentHeaderData} />
            <ProgressStepComponent current={currentStep} onStepsChange={onStepsChange} />
            {errorVisible && (<div className='errorContainer'>{formFieldErrorMessage}</div>)}
            {!errorVisible && (<div style={{ marginTop: "40px" }}></div>)}
            <SignupFormContainerComponent
                form={form}
                formItems={itemsToShow}
                onFinish={handleFormFinish}
                buttonText={buttonText}
                fieldErrors={fieldErrors}
                isLoading={isLoading}
                dropdownOptionsFromIndustryApi={dropdownOptionsFromIndustryApi}
            />
        </div>
    );
};

export default SignUpPageComponent;
