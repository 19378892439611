import React from "react";
import MainNavbarForDashboard from "../components/navbarComponents/NavbarForMainDashbaord";
import MainScreeContent from "../components/mainDashboardPageComponents/MainScreeContent";


import "../css/mainDashboardPage.css";
const isNavbarForDashboard = true;

const MainDashboardPage = () => {

    const user_details = JSON.parse(sessionStorage.getItem("user_details"));
    // console.log("in main dashboard page where the logged_in_user_id = ", user_details);

    return (
        <div className="completePageContainer">
            <MainNavbarForDashboard
                isNavbarForDashboard={isNavbarForDashboard}
                user_details={user_details}
                current_tab="dashboard"
            />
            <div className="mainContentBelowTheNavbar" style={{marginTop: "5rem"}}>
                <MainScreeContent user_details={user_details} />
            </div>
        </div>
    )
};

export default MainDashboardPage;