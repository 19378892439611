import React, { useState, useEffect } from "react";

const AssessmentReportSectionNavigationComponentForInvestmentReadiness = ({ parentStateForActiveTab, analysisTabRefs, investmentReadinessTabData }) => {
    const [activeSection, setActiveSection] = useState("Company Overview");

    const handleClickAssessmentReportAnalysisNavigationItem = (sectionName, ref) => {
        setActiveSection(sectionName);  // Set the active section
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="listOfAssessmentReportSections">

            <div
                className={(activeSection === "Company Overview") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Company Overview", analysisTabRefs.companyOverview)}
            >
                Company Overview
            </div>

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Market Opportunity"]) && (
                <div
                    className={(activeSection === "Market Opportunity") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Market Opportunity", analysisTabRefs.marketOpportunity)}
                >
                    Market Opportunity
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Competitive Landscape"]) && (
                <div
                    className={(activeSection === "Competitive Landscape") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Competitive Landscape", analysisTabRefs.competitiveLandscape)}
                >
                    Competitive Landscape
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Product and Development"]) && (
                <div
                    className={(activeSection === "Product and Development") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Product and Development", analysisTabRefs.productAndDevelopmentStage)}
                >
                    Product and Development
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Financial Metrics"]) && (
                <div
                    className={(activeSection === "Financial Metrics") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Financial Metrics", analysisTabRefs.financialMetrics)}
                >
                    Financial Metrics
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Traction and Market Penetration"]) && (
                <div
                    className={(activeSection === "Traction and Market Penetration") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Traction and Market Penetration", analysisTabRefs.tractionAndMarketPenetration)}
                >
                    Traction and Market Penetration
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Risk Analysis"]) && (
                <div
                    className={(activeSection === "Risk Analysis") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Risk Analysis", analysisTabRefs.riskAnalysis)}
                >
                    Risk Analysis
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Team and Experience"]) && (
                <div
                    className={(activeSection === "Team and Experience") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Team and Experience", analysisTabRefs.teamAndExperience)}
                >
                    Team and Experience
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Governance and Support"]) && (
                <div
                    className={(activeSection === "Governance and Support") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Governance and Support", analysisTabRefs.governanceAndSupport)}
                >
                    Governance and Support
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Revenue Model and Growth"]) && (
                <div
                    className={(activeSection === "Revenue Model and Growth") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Revenue Model and Growth", analysisTabRefs.revenueModelAndGrowth)}
                >
                    Revenue Model and Growth
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Exit Strategy"]) && (
                <div
                    className={(activeSection === "Exit Strategy") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Exit Strategy", analysisTabRefs.operationEfficiency)}
                >
                    Exit Strategy
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report.investment_readiness_report && investmentReadinessTabData.report.investment_readiness_report["Remediation"] && investmentReadinessTabData.report.investment_readiness_report["Remediation"]["Funding and Capital Requirements"]) && (
                <div
                    className={(activeSection === "Funding and Capital Requirements") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Funding and Capital Requirements", analysisTabRefs.fundingAndCapitalRequirements)}
                >
                    Funding and Capital Requirements
                </div>
            )}


            {/* <div
                className={(activeSection === "Recommendations & Improvement") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Recommendations & Improvement", analysisTabRefs.recommendations)}
            >
                Recommendations & Improvement
            </div>

            <div
                className={(activeSection === "Conclusion") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Conclusion", analysisTabRefs.investmentReadinessConclusion)}
            >
                Conclusion
            </div> */}
        </div>
    );
};

export default AssessmentReportSectionNavigationComponentForInvestmentReadiness;