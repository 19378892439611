import React from "react";
import analysisIcon from "../../../assets/linechartAnalysis.png";
import rankingIcon from "../../../assets/medal.png";
import operationalEfficiency from "../../../assets/operationalEfficiency.PNG";

const OperationalEfficiencyForInvestmentReadiness = ({ sectionData = {}, analysis = [], rankingData = "Ranking Value not available", completeReportData = {} }) => {

    var ranking_class_name = "#4B4B4B";
    if (rankingData === "Below Average") {
        ranking_class_name = "#F64C4C";
    } else if (rankingData === "Average") {
        ranking_class_name = "#FE9B0E";
    } else if (rankingData === "Above Average") {
        ranking_class_name = "#47B881";
    } else {
        ranking_class_name = "#4B4B4B";
    }

    let analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    if (Array.isArray(analysis)) {
        analysisSectionTextDescription = analysis[2] ? analysis[2] : "Analysis is not available at this moment for this metric.";
    }
    else if (typeof analysis === "object" && analysis !== null && !Array.isArray(analysis)) {
        analysisSectionTextDescription = analysis["Analysis"];
    } else {
        analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    }

    const assessment = completeReportData?.assessment || {};

    const combineWithAnd = (array) => {
        if (array.length === 0) return "";
        if (array.length === 1) return array[0];
        if (array.length === 2) return array.join(" and ");
        return array.slice(0, -1).join(", ") + ", and " + array[array.length - 1];
    };

    return (
        <div className="investmentReadinessMarketOpportunitySection">
            <div className="investmentReadinessMarketOpportunitySectionHeader">
                <div className="investmentReadinessMarketOpportunitySectionTitleIndex">10</div>
                <div className="investmentReadinessMarketOpportunitySectionTitleName">Exit Strategy</div>
            </div>
            <div className="investmentReadinessMarketOpportunitySectionContent">
                <div className="nonGraphicalContentForMarketOpportunity">
                    <div className="analysisContentForMarketOpportunity">
                        <div className="analysisContentTitle">
                            <img src={analysisIcon} alt="Idea" width="15px" height="15px"></img>
                            <div className="analsyisTitleName">Analysis</div>
                        </div>
                        <div className="analysisContentDescription">
                            {analysisSectionTextDescription}
                        </div>
                    </div>
                    <div className="rankingContentForMarketOpportunity">
                        <div className="rankingContentTitle">
                            <img src={rankingIcon} alt="Idea" width="20px" height="20px"></img>
                            <div className="rankingTitleName">Ranking</div>
                        </div>
                        <div className="rankingContentDescription">
                            <div className="keyInformationSectionLine">
                                <div className="keyDifferentiatorsKey">Exit plan:</div>
                                <div className="keyDifferentiatorsValue" style={{ color: ranking_class_name }}>{rankingData}</div>
                            </div>
                            {/* <div className="keyInformationSectionLine" style={{ marginTop: "5px" }}>
                                <div className="keyDifferentiatorsKey">Growth Rate:</div>
                                <div className="keyDifferentiatorsValue" style={{ color: ranking_class_name }}>{rankingData}</div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="gapSection"></div>
                <div className="graphicalComponentForMarketOpportunity">
                    <div className="analysisContentForMarketOpportunity">
                        <div className="rankingContentTitle">
                            <img src={rankingIcon} alt="Idea" width="20px" height="20px"></img>
                            <div className="rankingTitleName">Key Information</div>
                        </div>
                        <div className="rankingContentDescription">
                            <div className="keyInformationSectionLine">
                                <div className="keyDifferentiatorsKey">Exit plan:</div>
                                <div className="keyDifferentiatorsValue">{combineWithAnd(assessment?.investor_exit_plan)}</div>
                            </div>
                            {/* <div className="keyInformationSectionLine">
                                <div className="keyDifferentiatorsKey">Cost Management:</div>
                                <div className="keyDifferentiatorsValue">Assume average efficiency with no specific details provided</div>
                            </div> */}
                        </div>
                    </div>
                    <div className="someAnalysisImage">
                        <img src={operationalEfficiency} alt="Idea" width="110px" height="110px"></img>
                    </div>
                </div>
            </div>
            <div className="rankingContentForMarketOpportunity" style={{ marginTop: "3%" }}>
                <div className="rankingContentTitle">
                    <div className="rankingTitleName">Require Fixes</div>
                </div>
                <div className="analysisContentDescription">
                    {(sectionData && sectionData["Needs fixing"] && sectionData["Reason"]) && (
                        <>
                            {sectionData["Needs fixing"] ? "True" : "False"}, {sectionData["Reason"]}
                        </>
                    )}
                </div>
            </div>
            <div className="investmentReadinessSectionDivider"></div>
        </div>
    );
};

export default OperationalEfficiencyForInvestmentReadiness;