import React, { useState, useEffect } from "react";
import randomUserImage from "../../../assets/randomUserImage.jpeg";
import { getCommentCreatedDate } from "../../FormattedDate";
import sendButtonIcon from "../../../assets/sendButton.png";
import { notification } from "antd";
import apiDetails from "../../../assets/apiDetails/apiDetails.json";
import "../../../css/RemediationSidebarCommentsComponent.css";

const RemediationSidebarCommentsComponent = ({ remediationReportData, user_details }) => {
    // console.log("comments data coming in from the api is - ", remediationReportData);
    const [commentsData, setCommentsData] = useState([]);

    useEffect(() => {
        // This code runs when the component mounts and whenever the remediationReportData.comments changes
        setCommentsData(remediationReportData.comments ? remediationReportData.comments : []);
    }, []);  // Dependency array to only re-run if comments change

    // Step 1: Initialize state to keep track of the input value
    const [userInputComment, setUserInputComment] = useState('');
    const [api, contextHolder] = notification.useNotification();

    const sendComment = (user_comment) => {
        async function makePostCommentCall() {
            await Promise.all([
                postUserComment(user_comment)
            ]);
        }
        makePostCommentCall();
    }

    const postUserComment = async (user_comment) => {
        try {
            // making the api body 
            const apiJSONBody = {
                "comment": user_comment,
                "user_id": user_details.logged_in_user_id
            }

            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const professional_projects = "professional-projects";
            const fetch_url = `${url_domain}/${version_path}/${professional_projects}/${remediationReportData.id}/comment`;
            const response = await fetch(fetch_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                },
                body: JSON.stringify(apiJSONBody)
            });

            // console.log("response from the post comment API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                openErrorNotification(error_data);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("response data from the post comment API call response.json() parsing function");
            // console.log(data);

            setCommentsData([...commentsData, data.comment]);
            setUserInputComment("");

            // setAllRemediations(data.data);
        } catch (error) {
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
            // openErrorNotification(error);
        }
    };

    const openErrorNotification = (notificationParameters) => {
        // console.log("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Failed to send Comment',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };


    return (
        <div className="remediationSidebarCommentsSection">
            {contextHolder}
            <div className="remediationSidebarCommentsTitle">Comments</div>
            <div className="remediationSidebarCommentsContent">
                {commentsData.map((comment, index) => (
                    <div class="remediation-comment-container" key={comment.id}>
                        <div class="remediation-comment-avatar">
                            <img src={randomUserImage} alt="User Avatar" />
                        </div>
                        <div class="remediation-comment-content">
                            <div class="remediation-comment-user-details">
                                <span class="remediation-comment-user-name">{comment.user.name} . </span>
                                <span class="remediation-comment-comment-date">{getCommentCreatedDate(comment.createdAt)}</span>
                            </div>
                            <div class="remediation-comment-message">
                                {comment.comment}.
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="send-comment-input-box">
                <input type="text" placeholder="Add a commentt" value={userInputComment} onChange={(e) => setUserInputComment(e.target.value)} />
                <img src={sendButtonIcon} alt="Send" onClick={() => sendComment(userInputComment)} />
            </div>
        </div>
    )
};

export default RemediationSidebarCommentsComponent;